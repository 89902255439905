import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {OrderService} from '../../services/order.service';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../services/api.service';
import {UserService} from '../../services/user.service';
import {ProviderAddressModel} from '../../models/provider_address.model';
import {DeliveryRateModel} from '../../models/delivery_rate.model';
import {ProviderListModel} from '../../models/provider_list.model';
import {Subscription} from 'rxjs';
import {DeliveryTypeEnum} from '../../enums/delivery_type.enum';
import {ToastService} from '../../services/toast.service';
import {OrderMealModel} from "../../models/order_meal.model";
import {TranslateService} from "@ngx-translate/core";
import {AccountModel} from "../../models/account.model";
import {UserRoleEnum} from "../../enums/user_role.enum";
import {PaymentSheetEventsEnum, Stripe} from "@capacitor-community/stripe";
import {environment} from "../../../environments/environment";
import {CaseTypeEnum} from "../../enums/case_type.enum";
import {LoggedUserModel} from "../../models/logged_user.model";
import {PaymentService} from "../../services/payment.service";
import {HistoryOrderModel} from "../../models/history_order.model";
import {StripePaymentIntentModel} from "../../models/stripePaymentIntent.model";
import {StripeCustomerModel} from "../../models/stripeCustomer.model";
import {StripeEphemeralModel} from "../../models/stripeEphemeral.model";

@Component({
    selector: 'app-order-detail-modal',
    templateUrl: './order-detail-modal.component.html',
    styleUrls: ['./order-detail-modal.component.scss']
})
export class OrderDetailModalComponent {
    @Input() public menu: Array<OrderMealModel[]>;
    @Input() public ordersHistory: Array<HistoryOrderModel>;
    protected readonly Number = Number;
    protected readonly DeliveryTypeEnum = DeliveryTypeEnum;
    protected readonly Object = Object;
    protected readonly CaseTypeEnum = CaseTypeEnum;
    protected readonly UserRoleEnum = UserRoleEnum;
    public order: any;
    public providerAddress: Array<ProviderAddressModel> = [];
    public providerDeliveryRateList: Array<DeliveryRateModel> = [];
    public deliveryOptions: ProviderListModel;
    public loaded: boolean = false;
    public orderSubscription: Subscription;
    public account: AccountModel
    public loggedUser: LoggedUserModel
    public loadingStripeInfo: boolean = false

    constructor(
        public router: Router,
        private modalCtrl: ModalController,
        public orderSvc: OrderService,
        public helperSvc: HelperService,
        private apiSvc: ApiService,
        public userSvc: UserService,
        private toastSvc: ToastService,
        private translateSvc: TranslateService,
        private paymentSvc: PaymentService
    ) {

    }

    async ionViewWillEnter() {
        this.orderSubscription = this.orderSvc.orderSubject.subscribe((resp: any) => {
            this.order = resp;
        });
        this.providerAddress = await this.getProviderAddress();
        this.deliveryOptions = this.userSvc.getProviderList().filter(p => Number(p.id) === Number(this.userSvc.getProvider().id))[0];
        if (!this.deliveryOptions.client_delivery_price) {
            this.providerDeliveryRateList = await this.getProviderDeliveryRate();
        }
        this.loggedUser = this.userSvc.getUser();
        this.account = this.userSvc.getAccount();
        console.log("OrderDetailModalComponent:DELIVERY_OPTION", this.deliveryOptions)
        console.log("OrderDetailModalComponent:LOGGED_USER", this.loggedUser)
        console.log("OrderDetailModalComponent:ACCOUNT", this.account)
        console.log("OrderDetailModalComponent:ORDER", this.order)
        console.log("OrderDetailModalComponent:MENU", this.menu)
        console.log("OrderDetailModalComponent:ORDER_HISTORY", this.ordersHistory)
        this.orderSvc.setDefaultDelivery(this.deliveryOptions.delivery);
        this.loaded = true;
    }

    async getProviderAddress(): Promise<Array<ProviderAddressModel>> {
        return await this.apiSvc.getProviderAddress(this.userSvc.getProvider()?.id);
    }

    async getProviderDeliveryRate(): Promise<Array<any>> {
        return await this.apiSvc.getProviderDeliveryRate(this.userSvc.getProvider()?.id);
    }

    async handlePaymentAndOrder(): Promise<any> {
        this.loadingStripeInfo = true
        await Stripe.initialize({
            publishableKey: environment.stripeApiKey,
        });
        const customerResp: StripeCustomerModel = await this.paymentSvc.getStripeCustomer();
        const ephemeralKeyResp:StripeEphemeralModel = await this.paymentSvc.getStripeEphemeralKey();
        let toPay =  this.getTotalToPay() < 0 ? 0 : this.getTotalToPay()
        if((this.orderSvc.getOrderTotal((this.deliveryOptions.box === CaseTypeEnum.DISPOSABLE ? this.deliveryOptions.box_oneuse_price : 0)) + this.orderSvc.getTransportRate(this.providerDeliveryRateList, this.deliveryOptions.client_delivery_price)) < Number(this.deliveryOptions.minimalPayment)){
           toPay = Number(this.deliveryOptions.minimalPayment)
        }

        const paymentIntentResp: StripePaymentIntentModel = await this.paymentSvc.getStripePaymentIntent(this.deliveryOptions.id, toPay);

        // prepare PaymentSheet with CreatePaymentSheetOption.
        await Stripe.createPaymentSheet({
            paymentIntentClientSecret: paymentIntentResp.paymentIntent.client_secret,
            customerId: customerResp.customerId,
            customerEphemeralKeySecret: ephemeralKeyResp.ephemeralKey.secret,
            merchantDisplayName: 'Lunchdrive.cz'
        });

        // present PaymentSheet and get result.
        const result = await Stripe.presentPaymentSheet();
        if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
            await this.handleOrder();
            this.loadingStripeInfo = false
        } else {
            this.loadingStripeInfo = false
        }
    }

    async handleOrder(): Promise<any> {
        const providers = this.userSvc.getProviderList()
        const order = {
            provider: this.userSvc.getProvider().id,
            user: this.userSvc.getUser().user.id,
            asUser: false,
            isBulk: this.userSvc.allowedMultiple && providers[providers.findIndex(l => l.id === Number(this.userSvc.getProvider().id))].bulk_orders && this.userSvc.getUser().user.role === UserRoleEnum.FIRM,
            account: this.userSvc.getUser().user.account,
            orders: this.order
        };

        try {
            const resp: { ok: boolean, msg: string } = await this.apiSvc.placeUserBulkOrder(order);
            let emptyDaysCount: number = 0
            const providers: ProviderListModel[] = await this.apiSvc.getProviderList();
            if (providers && providers.length) {
                this.userSvc.setProviderList(providers);
                this.deliveryOptions = this.userSvc.getProviderList().filter(p => Number(p.id) === Number(this.userSvc.getProvider().id))[0];
            }
            for (let day of Object.keys(this.order)) {
                const index = Object.keys(this.order[day].orders).findIndex(o => this.order[day].orders[o].qty > 0);
                if (index < 0) {
                    emptyDaysCount += 1
                }
            }
            if (Object.keys(this.order).length === emptyDaysCount) {
                resp.msg = this.translateSvc.instant('page.client.order.text.checking-out')
            }
            resp['provider'] = order.provider
            await this.close(resp);
        } catch (e) {
            await this.toastSvc.presentToast(e, 'warning', 4000);
            console.log('PLACE ORDER ERROR: ', e);
        }
    }

    canMealUnOrder(date: string, mealId: string): boolean {
        if (this.menu[date]) {
            if (!mealId) {
                return false
            } else {
                if (this.menu[date].find((m: OrderMealModel) => m.id === mealId)) {
                    return this.menu[date].find((m: OrderMealModel) => m.id === mealId).userData.canUnOrder
                }
            }
        }
        return true
    }

    async close(data: any = null) {
        await this.modalCtrl.dismiss(data);
    }

    async goToPay(url: string) {
        await this.modalCtrl.dismiss();
        await this.router.navigate([url]);
    }

    ordersDisabled(): boolean {
        if (this.deliveryOptions) {
            if (this.deliveryOptions.no_debt) {
                if (Number(this.deliveryOptions.userBalance) <= 0) {
                    return true
                }
                let casePrice = 0;
                if (this.deliveryOptions.box === CaseTypeEnum.DISPOSABLE) {
                    casePrice = this.deliveryOptions.box_oneuse_price
                }
                if ((this.orderSvc.getOrderTotal(casePrice) + this.orderSvc.getTransportRate(this.providerDeliveryRateList, this.deliveryOptions.client_delivery_price)) >= Number(this.deliveryOptions.userBalance)) {
                    return true
                }
            }
        } else {
            return false
        }

    }

    getTotalToPay(): number{
        const total = (this.orderSvc.getOrderTotal((this.deliveryOptions.box === CaseTypeEnum.DISPOSABLE ? this.deliveryOptions.box_oneuse_price : 0)) + this.orderSvc.getTransportRate(this.providerDeliveryRateList, this.deliveryOptions.client_delivery_price))
        return ( total - this.orderSvc.getAlreadyOrderedAndPaid(this.ordersHistory, this.providerDeliveryRateList, this.deliveryOptions.client_delivery_price) - Number(this.deliveryOptions.userBalance)) < 0 ? 0 : (total - this.orderSvc.getAlreadyOrderedAndPaid(this.ordersHistory, this.providerDeliveryRateList, this.deliveryOptions.client_delivery_price) - Number(this.deliveryOptions.userBalance))
    }

}
