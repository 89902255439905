import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Platform} from '@ionic/angular';
import {Preferences} from '@capacitor/preferences';
import {JWT_KEY} from '../constants';
import {HttpService} from './http.service';
import {AccountActualModel} from '../models/account_actual.model';
import {ProviderModel} from '../models/provider.model';
import {ProviderListModel} from '../models/provider_list.model';
import {ProviderAddressModel} from '../models/provider_address.model';
import {LoggedUserDataModel} from '../models/logged_user.model';
import {ProviderLunchListModel} from '../models/provider_lunch_list.model';
import {HistoryOrderModel} from '../models/history_order.model';
import {AccountModel} from "../models/account.model";
import {StorageService} from "./storage.service";
import {UserService} from "./user.service";
import * as Sentry from "@sentry/capacitor";


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public user = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private httpSvc: HttpService,
        private storageSvc: StorageService,
        private userSvc: UserService,
        private plt: Platform
    ) {
        this.plt.ready().then(() => {
            Preferences.get({key: JWT_KEY}).then((data) => {
                if (data) {
                    // this.loggedUser.changeUserData(data);
                    this.user.next(data);
                }
            });
        });
    }

    async signIn(username, password): Promise<any> {
        const userData = (await this.http.post(`${environment.apiUrl}/login/token`, {username, password})).toPromise();
        //Sentry.captureMessage("App-SignIn", {user: {username: username, extra: {pwd: password}}})
        await Preferences.set({key: JWT_KEY, value: JSON.stringify(userData)});
        return userData;
    }

    signUp(parameters) {
        return this.http.post(`${environment.apiUrl}/account/register`, {
            parameters
        });
    }

    resetPassword(usernameOrEmail) {
        return this.http.post(`${environment.apiUrl}/wp/v2/users/lostpassword`, {
            user_login: usernameOrEmail
        });
    }

    getOrders(opts) {
        /*from, to, provider, account*/
        return this.http.get<any[]>(`${environment.apiUrl}/order/accountOrders`, {
            params: opts
        });
    }

    getPermissions() {
        return this.http.get<any>(`${environment.apiUrl}/rbacGetListOfOperationsAsJsObject?type=enum&useSymbol=0&objectName=MyRbacOperations`).toPromise();
    }

    getPrivatePosts() {
        return this.http
            .get<any[]>(`${environment.apiUrl}/wp/v2/posts?_embed&status=private`)
            .pipe(
                map((data) => {
                    for (const post of data) {
                        if (post['_embedded']['wp:featuredmedia']) {
                            post.media_url =
                                post['_embedded']['wp:featuredmedia'][0]['media_details'].sizes[
                                    'medium'
                                    ].source_url;
                        }
                    }
                    return data;
                })
            );
    }

    getCurrentUser() {
        return this.user.asObservable();
    }

    getUserValue() {
        return this.user.getValue();
    }

    async logout() {
        await this.storageSvc.removeItem(JWT_KEY);
        this.userSvc.setToken(null);
        this.userSvc.setUser(null);
    }

    getAddressGeoData(formatedAddress) {
        return this.http.get<any[]>(
            `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?address=${formatedAddress}&key=AIzaSyDHwqQPe6WSeE44FG9WR5B7ZLQsBac03hw`
        );
    }

    getProvidersInArea(latitude, longitude) {
        return this.http.get<any[]>(
            `${environment.apiUrl}/provider/list?lat=${latitude}&lng=${longitude}&addMenu=true`
        );
    }

    getProviderData(providerId) {
        return this.http.get<any[]>(`${environment.apiUrl}/provider/${providerId}`);
    }

    getAccountData(accountId) {
        return this.http.get<any[]>(`${environment.apiUrl}/account/${accountId}`);
    }

    updateAccountData(accountId, data) {
        return this.http.put<any[]>(`${environment.apiUrl}/account/${accountId}`, data);
    }

    emailExist(email) {
        return this.http.get<any[]>(
            `${environment.apiUrl}/account/emailExists?email=${email}`
        );
    }

    async getUserData(userId: string): Promise<LoggedUserDataModel> {
        return await this.httpSvc.send<LoggedUserDataModel>('GET', `/systemUser/${userId}`);
    }

    async getWeekMenu(from: string, to: string, providerId: number, foruser: string = null): Promise<ProviderLunchListModel> {
        return await this.httpSvc.send<ProviderLunchListModel>('GET', `/lunch/list?from=${from}&to=${to}&provider=${providerId}&foruser=${foruser}`);
        //return await this.httpSvc.send<Array<OrderMealModel[]>>('GET', `/lunch/list?from=${from}&to=${to}&provider=${providerId}`);
    }

    async addMealComment(data) {
        return await this.httpSvc.send('POST', `/order/comment`, data);
    }

    async addMealRating(data): Promise<{ id: number }> {
        return await this.httpSvc.send<{ id: number }>('POST', `/order/rate`, data);
    }

    getProviderClients() {
        return this.http.get<any[]>(`${environment.apiUrl}/account/clients`);
    }

    getClientUsers(id) {
        return this.http.get<any[]>(
            `${environment.apiUrl}/account/clientUsers?clientId=${id}`
        );
    }

    async placeUserBulkOrder(order: any): Promise<{ ok: boolean, msg: string }> {
        return await this.httpSvc.send<{ ok: boolean, msg: string }>('POST', `/order/placeUserBulkOrder`, order);
    }

    async getProviderAddress(providerId: string | number): Promise<Array<ProviderAddressModel>> {
        return await this.httpSvc.send<Array<ProviderAddressModel>>('GET', `/provider/address?provider=${providerId}`);
    }

    async getProviderDeliveryRate(providerId: string | number): Promise<Array<any>> {
        return await this.httpSvc.send<Array<any>>('GET', `/deliveryRate/list?provider=${providerId}`);
    }

    getBilling(parameters) {
        return this.http.get<any[]>(`${environment.apiUrl}/order/billing?`, {
            params: parameters
        });
    }

    getClientsTag() {
        return this.http.get<any[]>(`${environment.apiUrl}/account/clientsTag`);
    }

    getProviderOrders(date) {
        return this.http.get<any[]>(
            `${environment.apiUrl}/order/list?date=${date}`
        );
    }

    getCanteen(parameters) {
        return this.http.get<any[]>(`${environment.apiUrl}/provider/canteen`, {
            params: parameters
        });
    }

    getTerminal(parameters) {
        return this.http.get<any[]>(`${environment.apiUrl}/provider/terminal`, {
            params: parameters
        });
    }

    async getProviderInfo(providerId: string | number): Promise<ProviderModel> {
//        return this.http.get<any[]>(`${environment.apiUrl}/account/info`);
        return await this.httpSvc.send<ProviderModel>('GET', `/provider/${providerId}`);
    }

    async getProviderList(): Promise<Array<ProviderListModel>> {
        return await this.httpSvc.send<Array<ProviderListModel>>('GET', '/SystemUser/providers');
    }

    getLunchList(providerId, date) {
        return this.http.get<any[]>(
            `${environment.apiUrl} / lunch / list ? provider =${providerId} & from =${date}
 &
        to =${date}`
        );
    }

    getBoxes() {
        return this.http.get<any[]>(`${environment.apiUrl} / account / boxes`);
    }

    getPayment(parameters) {
        return this.http.get<any[]>(`${environment.apiUrl} / account / payment`, {
            params: parameters
        });
    }

    async getProfile(id: string | number): Promise<AccountModel> {
        return await this.httpSvc.send<AccountModel>('GET', `/account/${id}`);
    }

    async getNews() {
        return await this.httpSvc.send('GET', '/account/news');
    }

    async getAccountActual(): Promise<AccountActualModel> {
        return await this.httpSvc.send<AccountActualModel>('GET', '/account/actual');
    }

    async getAccountOrders(from: string, to: string, userId: number, range: boolean = false): Promise<HistoryOrderModel[]> {
        if (!range) {
            to = from;
        }
        return await this.httpSvc.send<HistoryOrderModel[]>('GET', `/order/userOrders?from=${from}&to=${to}&user=${userId}`);
    }

    getRating(parameters) {
        return this.http.get<any[]>(`${environment.apiUrl} / provider / rating`, {
            params: parameters
        });
    }

    getFirmProvider() {
        return this.http.get<any[]>(`${environment.apiUrl} / account / firmProvider`);
    }

    getFirmOrders(parameters) {
        return this.http.get<any[]>(`${environment.apiUrl} / order / firmOrders`, {
            params: parameters
        });
    }

    addLunch(parameters) {
        // todo: create api on backend
        return this.http.post(`${environment.apiUrl} / account / addLunch`, {
            parameters
        });
    }

    getLunch(lunchId) {
        return this.http.get(`${environment.apiUrl} / lunch / getLunch ? id =${lunchId}`);
    }

    addClient(parameters) {
        // todo: create api on backend
        return this.http.post(`${environment.apiUrl} / account / addClient`, {
            parameters
        });
    }

    getCreditCard(accountId) {
        return this.http.get(`${environment.apiUrl} / account / listCreditCards ? id =${accountId}`);
    }

    removeCreditCard(accountId, stripeToken) {
        return this.http.get(`${environment.apiUrl} / account / deleteCreditCard ? id =${accountId}
    &
        token =${stripeToken}`);
    }

    addCreditCard(accountId, stripeToken) {
        return this.http.post(`${environment.apiUrl} / account / addCreditCard`, {
            id: accountId,
            token: stripeToken
        });
    }

    async updateSystemUser(SystemUser, idField, account, userId): Promise<any> {
        return await this.httpSvc.send<any>('POST', '/systemUser/addUpdateUser?idfield=' + idField + '&account=' + account + '&id=' + userId, SystemUser);
        //return this.http.post(`${environment.apiUrl} / systemUser / addUpdateUser ? idfield =${idField}`, user);
    }
}
