import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import {
    HttpClientModule,
    HttpClient
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { UserService } from './services/user.service';
import { ProviderOverviewService } from './pages/provider-overview/provider-overview.service';
import { ProviderOfferService } from './pages/provider-offer/provider-offer.service';
import { ProviderClientsService } from './pages/provider-clients/provider-clients.service';
import { NewsService } from './pages/news/news.service';
import { StorageService } from './services/storage.service';
import localeCs from '@angular/common/locales/cs';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import {PaymentService} from "./services/payment.service";
import * as Sentry from '@sentry/angular';

registerLocaleData(localeCs, 'cs');
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            swipeBackEnabled: false
        }),
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        ComponentsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatCardModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => (): any => {
            },
            deps: [Sentry.TraceService],
            multi: true
        },
        provideLottieOptions({
            player: () => player,
        }),
        ProviderOverviewService,
        ProviderOfferService,
        ProviderClientsService,
        UserService,
        NewsService,
        StorageService,
        PaymentService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
