import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {HelperService} from "./helper.service";

@Injectable({
    providedIn: 'root'
})
export class OrderGuardService {
    private redirectUrl: string

    constructor(
        private alertCtrl: AlertController,
        private translateSvc: TranslateService,
        private router: Router,
        private helperSvc: HelperService
    ) {
        this.router.events.subscribe((event: any) => {
            if (event.constructor.name === 'NavigationStart') {
                this.redirectUrl = event.url;
            }

        });
    }

    async canDeactivate() {
        if (!this.helperSvc.orderComplete && this.helperSvc.orderNewMeals > 0 ) {
            const alert = await this.alertCtrl.create({
                header: this.translateSvc.instant('page.client.order.text.page_leave'),
                message: this.translateSvc.instant('page.client.order.text.page_leave_message'),
                buttons: [
                    {
                        text: this.translateSvc.instant('alert.button.cancel'),
                        handler: async () => {
                            await alert.dismiss()
                        }
                    },
                    {
                        text: this.translateSvc.instant('alert.button.ok'),
                        handler: () => {
                            this.helperSvc.orderComplete = true;
                            this.helperSvc.orderNewMeals = 0;
                            this.router.navigate([this.redirectUrl])
                        }
                    }
                ]
            });
            await alert.present();
            return false;
        }
    }
}
