import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {UserService} from '../../services/user.service';
import {UserRoleEnum} from '../../enums/user_role.enum';
import {LoggedUserModel} from '../../models/logged_user.model';
import {Platform, PopoverController} from '@ionic/angular';
import {MenuPopoverComponent} from '../menu-popover/menu-popover.component';
import {HelperService} from "../../services/helper.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    readonly userRoleEnum = UserRoleEnum;
    @Input() pageName: string;
    @Input() profileMenu = false;
    userAcronym: string = '';
    userData: LoggedUserModel;

    constructor(
        private apiSvc: ApiService,
        public router: Router,
        private userSvc: UserService,
        public platform: Platform,
        private popoverCtrl: PopoverController,
        public helperSvc: HelperService
    ) {
    }

    async ngOnInit() {
        this.userData = await this.userSvc.getUser();
        if (this.userData.user && this.userData.user.name) {
            this.userAcronym = this.userData.user.name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
        }
    }

    async presentMenuPopover(ev: any) {
        const popover = await this.popoverCtrl.create({
            component: MenuPopoverComponent,
            cssClass: 'ion-popover--user-menu',
            event: ev,
            componentProps: {
                userData: this.userData
            }
        });
        await popover.present();

        await popover.onDidDismiss().then((resp) => {
            const page = resp?.data?.page;
            if (page) {
                if (page !== '/logout') {
                    this.router.navigate([page]);
                } else {
                    this.logout();
                }
            }
        });
        await popover.present();
    }

    logout() {
        this.profileMenu = false;
        this.apiSvc.logout();
        this.router.navigate([''], {replaceUrl: true});
    }
}
