// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    mode: 'Development',
    production: false,
    firebase: {
        apiKey: 'AIzaSyC5QK355uuknu0_ldVxFqqNqgp9oJi_eLc',
        authDomain: 'ion4fullpwa.firebaseapp.com',
        databaseURL: 'https://ion4fullpwa.firebaseio.com',
        projectId: 'ion4fullpwa',
        storageBucket: 'ion4fullpwa.appspot.com'
    },
    appShellConfig: {
        debug: false,
        networkDelay: 500
    },
    stripeApiKey: 'pk_test_51HbVmLJUNMBDs1naqtqH2pYKhTo05IyKj2UbGmb5x0CP88puhBwzGfbmoUISbDGs0Lb4OeBfHD7tGwk2P848kkCW00KMohA4Sa',
    stripeAccountId: 'acct_1Hf3keKQaq03voV7',
    sentryUrl: 'https://5c74ce2fd976ea20c729973aed09391d@o4507938562572288.ingest.de.sentry.io/4507938567094352',
    apiUrl: 'https://lunchdrive.cz/api',
    server: 'https://www.lunchdrive.cz'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
