import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MarketableLunchModel} from "../models/marketable_lunch.model";
import {MarketLunchModel} from "../models/market_lunch.model";
import {ApiService} from "./api.service";
import {MarketModel} from "../models/market.model";
import {ToastService} from "./toast.service";
import {UserService} from "./user.service";
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class LunchMarketService {

    public marketableSubject: any = new BehaviorSubject<Array<MarketableLunchModel>>([]);
    public marketable: any = this.marketableSubject.asObservable();

    public marketAvailableSubject: any = new BehaviorSubject<Array<MarketLunchModel>>([]);
    public marketAvailable: any = this.marketAvailableSubject.asObservable();

    public marketSubject: any = new BehaviorSubject<MarketModel>(null);
    public market: any = this.marketSubject.asObservable();

    constructor(
        private apiSvc: ApiService,
        private toastSvc: ToastService,
        private userSvc: UserService,
        private alertCtrl: AlertController,
        private translateSvc: TranslateService
    ) {

    }

    async presentMarketConfirmAlert(market: MarketModel, lunch: MarketableLunchModel | MarketLunchModel, action: string = '') {
        let title = '';
        let message = '';
        if (action === 'add') {
            title = this.translateSvc.instant('page.lunch_market.alert.title.add-in-lunchmarket')
            message = this.translateSvc.instant('page.lunch_market.alert.text.add-in-lunchmarket', {lunch: lunch['lunchObj'].name})
        }
        if (action === 'remove') {
            title = this.translateSvc.instant('page.lunch_market.alert.title.remove-from-lunchmarket')
            message = this.translateSvc.instant('page.lunch_market.alert.text.remove-from-lunchmarket', {lunch: lunch['lunchObj'].name})
        }
        if (!action) {
            title = this.translateSvc.instant('page.lunch_market.alert.title.buy-from-lunchmarket')
            message = this.translateSvc.instant('page.lunch_market.alert.text.buy-from-lunchmarket', {lunch: lunch['name']})
        }
        const alert = await this.alertCtrl.create({
            header: title,
            message,
            buttons: [
                {
                    text: this.translateSvc.instant('alert.button.cancel'),
                    handler: async () => {
                        await alert.dismiss()
                    }
                },
                {
                    text: this.translateSvc.instant('alert.button.ok'),
                    handler: () => {
                        if (!action) {
                            this.buyFromMarket(market, lunch)
                        } else {
                            this.handleMarketLunch(market, lunch, action)
                        }
                    }
                }
            ]
        });
        await alert.present();
    }

    async handleMarketLunch(market: MarketModel, lunch: MarketableLunchModel | MarketLunchModel, action: string) {
        const resp = await this.apiSvc.addLunchToMarket(market.id, lunch.id, action)
        await this.getUserLunchMarketData();
        let message = this.translateSvc.instant('page.lunch_market.toast.message.added-successfully')
        if (action === 'remove')
            message = this.translateSvc.instant('page.lunch_market.toast.message.taken-successfully')
        await this.toastSvc.presentToast(message, 'success')
        return resp
    }

    async buyFromMarket(market, order) {
        const buyData = {
            market: market.id,
            user: this.userSvc.getUser().user.id,
            lunch: order.id,
            box: order.on_market_box,
            date: order.date,
            action: 'pull'
        }
        const resp = await this.apiSvc.buyFromMarket(buyData);
        if (resp && resp.success) {
            await this.toastSvc.presentToast(resp.msg, 'success')
        }
        await this.getUserLunchMarketData()
        return resp
    }

    async getUserLunchMarketData() {
        const marketData = await this.apiSvc.getUserLunchMarketData();
        this.marketSubject.next(marketData.market)
        this.marketableSubject.next(marketData.marketable)
        this.marketAvailableSubject.next(marketData.available)
        return marketData
    }


    public async clear(): Promise<void> {
        this.marketableSubject.next(null);
        this.marketAvailableSubject.next(null);
        this.marketSubject.next(null);

    }

}
