// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
}
:host [slot=helper] {
  position: absolute;
  z-index: 99;
  right: 10px;
  top: 38px;
}
:host .error-container {
  position: absolute;
  right: 0;
  bottom: -33px;
  z-index: 99;
}
:host .type-toggle .show-option,
:host .type-toggle .hide-option {
  font-size: 1.2rem;
  display: block;
}
:host .type-toggle .show-option:not(ion-icon),
:host .type-toggle .hide-option:not(ion-icon) {
  text-transform: uppercase;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/show-hide-password/show-hide-password.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;AACC;EACC,kBAAA;EACA,WAAA;EACA,WAAA;EACA,SAAA;AACF;AAEC;EACC,kBAAA;EACA,QAAA;EACA,aAAA;EACA,WAAA;AAAF;AAIE;;EAEC,iBAAA;EACA,cAAA;AAFH;AAKG;;EACC,yBAAA;EACA,eAAA;AAFJ","sourcesContent":[":host {\n\tposition: relative;\n\n\t[slot=\"helper\"] {\n\t\tposition: absolute;\n\t\tz-index: 99;\n\t\tright: 10px;\n\t\ttop: 38px;\n\t}\n\n\t.error-container {\n\t\tposition: absolute;\n\t\tright: 0;\n\t\tbottom: -33px;\n\t\tz-index: 99;\n\t}\n\n\t.type-toggle {\n\t\t.show-option,\n\t\t.hide-option {\n\t\t\tfont-size: 1.2rem;\n\t\t\tdisplay: block;\n\n\t\t\t// In case you want to use text instead of icons\n\t\t\t&:not(ion-icon) {\n\t\t\t\ttext-transform: uppercase;\n\t\t\t\tfont-size: 1rem;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
