import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {OrderMealModel} from '../models/order_meal.model';
import {StorageService} from './storage.service';
import {DeliveryOptionsModel} from '../models/delivery_options.model';
import {DeliveryTypeEnum} from '../enums/delivery_type.enum';
import {ProviderListModel} from '../models/provider_list.model';
import {DeliveryRateModel} from '../models/delivery_rate.model';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ProviderModel} from '../models/provider.model';
import {HistoryOrderModel} from "../models/history_order.model";
import {HelperService} from "./helper.service";
import {CaseTypeEnum} from "../enums/case_type.enum";

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    public orderSubject: any = new BehaviorSubject<any>(null);
    public order: any = this.orderSubject.asObservable();
    public orderLength: number = 0;
    private orderSubscription: Subscription;

    constructor(
        private storageSvc: StorageService,
        private alertCtrl: AlertController,
        private helperSvc: HelperService,
        private translateSvc: TranslateService
    ) {
        this.orderSubscription = this.order.subscribe(async (resp) => {
            this.orderLength = this.getOrdersLength(resp);
        });
    }

    public async orderMeal(date: string, meal: any, provider: ProviderListModel, allowedMultiple: boolean, historyMeal: HistoryOrderModel = null, menu: Array<OrderMealModel[]> = null): Promise<void> {
        let orders = {};

        if (historyMeal) {
            meal = {
                id: historyMeal.lunchId,
                name: historyMeal.name,
                note: historyMeal.note,
                branch: historyMeal.address_id ? historyMeal.address_id : 0,
                delivery: historyMeal.address_id ? 'takeaway' : 'delivery',
                deliveryPrice: historyMeal.address_id ? 'takeaway' : 'delivery',
                userData: {
                    price: historyMeal.price,
                    boxes: historyMeal.box === CaseTypeEnum.DISPOSABLE ? 1 : provider.optional_case && Number(historyMeal.box) > 0 ? 1 : !provider.optional_case && provider.box === CaseTypeEnum.DISPOSABLE ? 1 : 0,
                    boxPrice: Number(historyMeal.case_price),
                    optionalCase: menu[historyMeal.lunchDate].find(m => m.id === historyMeal.lunchId).userData.optionalCase,
                    ordered: '1'
                }
            };
        }
        if (this.orderSubject.value) { //check if order already exists
            orders = this.orderSubject.value;
            if (orders[date]) { //check if order includes selected meal date
                if (allowedMultiple) {
                    if (orders[date].orders[meal.id]) { //check if order date includes selected meals
                        orders[date].orders[meal.id].qty += 1;
                        orders[date].orders[meal.id].box += historyMeal ? meal.userData.boxes : meal.userData.optionalCase === '1' ? 0 : meal.userData.optionalCase === '0' && provider.box === CaseTypeEnum.DISPOSABLE ? 1 : 0;
                        orders[date].orders[meal.id].boxPrice = meal.userData.boxPrice
                        this.helperSvc.orderNewMeals += 1;

                    } else {
                        const orderObj = this.createDateOrderObj(date, meal, provider);
                        orders[date].orders = Object.assign(orders[date].orders, orderObj[date].orders);
                    }
                } else {
                    if (orders[date].orders[meal.id]) {
                        delete orders[date].orders[meal.id]
                        this.helperSvc.orderNewMeals -= 1;
                    } else {
                        if (meal.onlyOne === '1') {
                            for (let mealId of Object.keys(orders[date].orders)) {
                                if (menu[date].find(m => m.id === mealId).onlyOne === '1') {
                                    delete orders[date].orders[mealId]
                                    this.helperSvc.orderNewMeals -= 1;
                                }
                            }
                        }
                        orders[date].orders[meal.id] = {
                            id: Number(meal.id),
                            qty: 1,
                            box: meal.userData.ordered ? meal.userData.boxes : meal.userData.optionalCase === '1' ? 0 : meal.userData.optionalCase === '0' && provider.box === CaseTypeEnum.DISPOSABLE ? 1 : 0,
                            name: meal.name,
                            price: meal.userData.price,
                            boxPrice: meal.userData.boxPrice,
                            optionalCase: Number(meal.userData.optionalCase)
                        }
                        this.helperSvc.orderNewMeals += 1;

                    }
                }
            } else {
                const orderObj = this.createDateOrderObj(date, meal, provider);
                orders = Object.assign(orders, orderObj);
            }
            await this.storageSvc.setItem('userOrder', JSON.stringify(await this.placeOrderUnderProvider(provider, orders)));
            this.orderSubject.next(orders);
        } else {
            const orderObj = this.createDateOrderObj(date, meal, provider);

            await this.storageSvc.setItem('userOrder', JSON.stringify(await this.placeOrderUnderProvider(provider, orderObj)));
            this.orderSubject.next(orderObj);

        }
    }

    async placeOrderUnderProvider(provider: ProviderModel | ProviderListModel, orders) {
        const order = await this.storageSvc.getItem('userOrder');
        let providerOrder = {};
        if (order) {
            providerOrder = JSON.parse(order);
            if (providerOrder[provider.id]) {
                providerOrder[provider.id] = orders;
            } else {
                const obj = {
                    [provider.id]: orders
                };
                providerOrder = Object.assign(providerOrder, obj);
            }
        } else {
            const obj = {
                [provider.id]: orders
            };
            providerOrder = Object.assign(providerOrder, obj);

        }
        return providerOrder;
    }

    private createDateOrderObj(date: string, meal: OrderMealModel, provider: ProviderListModel = null) {
        const order = {
            [date]: {
                orders: {
                    [meal.id]: {
                        id: Number(meal.id),
                        qty: 1,
                        box: meal.userData.ordered ? meal.userData.boxes : meal.userData.optionalCase === '1' ? 0 : meal.userData.optionalCase === '0' && provider.box === CaseTypeEnum.DISPOSABLE ? 1 : 0,
                        name: meal.name,
                        price: meal.userData.price,
                        boxPrice: meal.userData.boxPrice,
                        optionalCase: Number(meal.userData.optionalCase)
                    }
                },
                note: meal.note ? meal.note : '',
                branch: meal.branch ? meal.branch : 0,
                delivery: {
                    selected: meal.delivery ? meal.delivery : '',
                    price: 0
                }
            }
        }
        this.helperSvc.orderNewMeals += 1;
        return order;
    }

    public getOrdersLength(order): number {
        if (order) {
            let length = 0;
            for (const dayKey of Object.keys(order)) {
                const ordersKeys = Object.keys(order[dayKey].orders);
                for (const key of ordersKeys) {
                    if (order[dayKey].orders[key].qty > 0)
                        length += order[dayKey].orders[key].qty;
                }
            }
            return length;
        }
    }

    public getOrder(): any {
        return this.orderSubject.value;
    }

    public async clearOrder(): Promise<void> {
        this.orderSubject.next({});
        await this.storageSvc.setItem('userOrder', JSON.stringify({}));

    }

    public getDailyOrderTotal(day: string): number {
        const order = this.getOrder();
        let dailyOrderPriceTotal: number = 0;
        if (order) {
            for (const dayOrder of Object.keys(order[day].orders)) {
                dailyOrderPriceTotal = dailyOrderPriceTotal + order[day].orders[dayOrder].qty * Number(order[day].orders[dayOrder].price);
            }

        }
        return dailyOrderPriceTotal;
    }

    public getHistoryDailyOrderTotal(day: string, history: any): number {
        const order = history;
        let dailyOrderPriceTotal: number = 0;
        if (order) {
            for (const dayOrder in Object.keys(order[day])) {
                dailyOrderPriceTotal = dailyOrderPriceTotal + Number(order[day][dayOrder].price);
            }

        }
        return dailyOrderPriceTotal;
    }

    public getOrderTotal(casePrice: number = 0): number {
        const order = this.getOrder();
        let orderPriceTotal: number = 0;
        if (order) {
            for (const keyDay of Object.keys(order)) {
                for (const keyOrder of Object.keys(order[keyDay].orders)) {
                    orderPriceTotal = orderPriceTotal + (order[keyDay].orders[keyOrder].qty * Number(order[keyDay].orders[keyOrder].price)) + (order[keyDay].orders[keyOrder].box * casePrice);
                }
            }
        }
        return orderPriceTotal;
    }

    public async checkOrder(provider: ProviderModel): Promise<void> {
        const order = await this.storageSvc.getItem('userOrder');
        if (order) {
            if (JSON.parse(order)[provider.id]) {
                this.orderSubject.next(JSON.parse(order)[provider.id]);
            } else {
                this.orderSubject.next(null);
            }
        } else {
            this.orderSubject.next(null);

        }
    }

    public async updateDayOrderOnDeliveryChange(dayOrder: any, key: string, deliveryOptions: ProviderListModel, providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number): Promise<void> {
        if (dayOrder.delivery.selected === DeliveryTypeEnum.TAKEAWAY && !dayOrder.branch && deliveryOptions[deliveryOptions.delivery.selected_branch]) {
            dayOrder.branch = deliveryOptions.delivery.selected_branch;
        } else {
            if (!dayOrder.branch) {
                dayOrder.branch = Object.keys(deliveryOptions.delivery.branches)[0];
            }
        }
        if (dayOrder.delivery.selected === DeliveryTypeEnum.DELIVERY) {
            dayOrder.delivery.price = this.getTransportRate(providerDeliveryRateList, clientDeliveryPrice);
        } else {
            dayOrder.delivery.price = 0;
        }
        const orders = this.getOrder();
        orders[key] = dayOrder;
        this.orderSubject.next(orders);
        await this.storageSvc.setItem('userOrder', JSON.stringify(orders));

    }

    public setDefaultDelivery(deliveryOptions: DeliveryOptionsModel): void {
        const order = this.getOrder();
        for (const day in order) {
            if (!order[day].delivery.selected) {
                if (deliveryOptions.delivery_options[DeliveryTypeEnum.TAKEAWAY] && deliveryOptions.selected_branch && deliveryOptions.selected_delivery === DeliveryTypeEnum.TAKEAWAY) {
                    order[day].delivery.selected = DeliveryTypeEnum.TAKEAWAY;
                    order[day].branch = deliveryOptions.selected_branch;
                } else {
                    order[day].branch = null;
                    if (deliveryOptions.delivery_options.hasOwnProperty(deliveryOptions.selected_delivery)) {
                        order[day].delivery.selected = deliveryOptions.selected_delivery;
                    } else {
                        order[day].delivery.selected = Object.keys(deliveryOptions.delivery_options)[0];
                    }
                    if (order[day].delivery.selected === DeliveryTypeEnum.TAKEAWAY) {
                        order[day].branch = Object.keys(deliveryOptions.branches)[0]
                    }
                }
            }
        }
        this.orderSubject.next(order);
    }

    getDailyTransportRate(key: string, providerDeliveryRateList: Array<DeliveryRateModel>): number {
        const orderDailyTotal = this.getDailyOrderTotal(key);
        if (providerDeliveryRateList.length) {
            return Number(providerDeliveryRateList.filter(d => Number(d.order_price_from) <= orderDailyTotal && Number(d.order_price_to ? d.order_price_to : '1000000') >= orderDailyTotal)[0].price);
        }
    }

    getHistoryDailyTransportRate(key: string, providerDeliveryRateList: Array<DeliveryRateModel>, history: any): number {
        const orderDailyTotal = this.getHistoryDailyOrderTotal(key, history);
        if (providerDeliveryRateList.length) {
            return Number(providerDeliveryRateList.filter(d => Number(d.order_price_from) <= orderDailyTotal && Number(d.order_price_to ? d.order_price_to : '1000000') >= orderDailyTotal)[0].price);
        }
    }

    getTransportRate(providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number, history = null): number {
        let orderTotal = this.getOrderTotal();
        if (history) {
            orderTotal = 0
            for (const historyMeal of Object.keys(history)) {
                for (let dayMeal of history[historyMeal]) {
                    orderTotal += (Number(dayMeal.price) + Number(dayMeal.case_price))
                }
            }
        }
        if (providerDeliveryRateList.length) {
            if ((history ? this.getHistoryDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice, history) : this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice)) > 0) {
                return Number(providerDeliveryRateList.filter(d => Number(d.order_price_from) <= orderTotal && Number(d.order_price_to ? d.order_price_to : '1000000') >= orderTotal)[0].price);
            } else {
                return 0;
            }
        } else {
            return history ? this.getHistoryDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice, history) : this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice)
        }
    }

    public getOrderDeliveryTotal(providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number): number {
        let deliveryTotal: number = 0;
        const order = this.getOrder();
        for (const day in order) {
            const index = Object.keys(order[day].orders).findIndex(o => order[day].orders[o].qty > 0);
            if (index > -1) {
                if (order[day].delivery.selected && order[day].delivery.selected === DeliveryTypeEnum.DELIVERY) {
                    if (clientDeliveryPrice) {
                        deliveryTotal += clientDeliveryPrice;
                    } else {
                        deliveryTotal += this.getDailyTransportRate(day, providerDeliveryRateList);
                    }
                }
            }
        }
        return deliveryTotal;
    }

    public getHistoryDeliveryTotal(providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number, history: any): number {
        let deliveryTotal: number = 0;
        const order = history;
        for (const day of Object.keys(order)) {
            if (order[day] && order[day][0] && order[day][0].deliver === '1') {//is delivery
                if (clientDeliveryPrice) {
                    deliveryTotal += clientDeliveryPrice;
                } else {
                    deliveryTotal += this.getHistoryDailyTransportRate(day, providerDeliveryRateList, history);
                }
            }
        }
        return deliveryTotal;
    }

    public getTotalToPay(deliveryOptions: ProviderListModel, providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number, ordersHistory: Array<HistoryOrderModel>): number {
        const order = this.getOrder();
        let orderPrice: number = 0;
        let historyPrice: number = 0;
        let historyDeliveryPrice = 0
        if (ordersHistory) {
            historyPrice = this.getAlreadyOrderedAndPaid(ordersHistory, providerDeliveryRateList, clientDeliveryPrice)
            const groupedHistoryByDay = this.helperSvc.groupByKey(ordersHistory, 'lunchDate')

            for (const historyDay of Object.keys(groupedHistoryByDay)) {
                if (groupedHistoryByDay[historyDay][0].deliver === '1') {
                    historyDeliveryPrice += historyDeliveryPrice + this.getTransportRate(providerDeliveryRateList, clientDeliveryPrice, groupedHistoryByDay)
                }
            }
        }
        if (order) {
            for (const keyDay of Object.keys(order)) {
                for (const keyOrder of Object.keys(order[keyDay].orders)) {
                    let qty = order[keyDay].orders[keyOrder].qty
                    const casePrice = (deliveryOptions.box === CaseTypeEnum.DISPOSABLE ? deliveryOptions.box_oneuse_price : 0);
                    orderPrice = orderPrice + (qty * Number(order[keyDay].orders[keyOrder].price)) + (order[keyDay].orders[keyOrder].box * casePrice);
                }
            }
        }
        const delivery = ((this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice) - historyDeliveryPrice) >= historyDeliveryPrice) ? (this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice) - historyDeliveryPrice) : historyDeliveryPrice
        return orderPrice - historyPrice < 0 ? 0 : orderPrice - historyPrice + delivery;
    }


    public getAlreadyOrderedAndPaid(ordersHistory: Array<HistoryOrderModel>, providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number): number {
        let historyPrice = 0
        const groupedHistoryByDay = this.helperSvc.groupByKey(ordersHistory, 'lunchDate')

        for (const historyMeal of ordersHistory) {
            historyPrice += (Number(historyMeal.price) + Number(historyMeal.case_price))
        }
        let historyDeliveryPrice = 0
        for (const historyDay of Object.keys(groupedHistoryByDay)) {
            if (groupedHistoryByDay[historyDay][0].deliver === '1') {
                historyDeliveryPrice += historyDeliveryPrice + this.getTransportRate(providerDeliveryRateList, clientDeliveryPrice, groupedHistoryByDay)
            }
        }
        return historyPrice + historyDeliveryPrice
    }

    public async updateMealQty(date: string, mealId: string, qty: number, provider: ProviderListModel): Promise<void> {
        const order = this.getOrder();
        if (order[date].orders[mealId].qty === 1 && qty === -1) {
            await this.presentRemoveMealAlert(order, date, mealId, provider)
        } else {
            order[date].orders[mealId].qty = order[date].orders[mealId].qty + qty;
            this.helperSvc.orderNewMeals = this.helperSvc.orderNewMeals + qty;
            if (order[date].orders[mealId].box > order[date].orders[mealId].qty) {
                order[date].orders[mealId].box = order[date].orders[mealId].qty;
            }
            if (Number(order[date].orders[mealId].optionalCase) === 0 && provider.box === CaseTypeEnum.DISPOSABLE) {
                order[date].orders[mealId].box = order[date].orders[mealId].qty;
            }
            await this.updateOrder(order, provider.id);
        }
    }

    public async updateMealQtyInput(date: string, mealId: string, provider: ProviderListModel, event: any): Promise<void> {
        const order = this.getOrder();
        if (!event.detail.value) {
            await this.presentRemoveMealAlert(order, date, mealId, provider)
        } else {
            order[date].orders[mealId].qty = Number(event.detail.value)
        }
    }

    public async updateMealBoxQty(date: string, mealId: string, qty: number, providerId: string | number): Promise<void> {
        const order = this.getOrder();
        order[date].orders[mealId].box = Number(order[date].orders[mealId].box) + qty;
        await this.updateOrder(order, providerId);
    }

    async presentRemoveMealAlert(order: any, date: string, mealId: string, provider: ProviderListModel) {
        const alert = await this.alertCtrl.create({
            header: this.translateSvc.instant('alert.header.meal-remove'),
            subHeader: order[date].orders[mealId].name,
            buttons: [
                {
                    text: this.translateSvc.instant('alert.button.cancel'),
                    role: 'cancel',
                    handler: async () => {
                        order[date].orders[mealId].qty = 1;
                        order[date].orders[mealId].box = !provider.optional_case && provider.box === CaseTypeEnum.DISPOSABLE ? 1 : 0;
                        await this.updateOrder(order, provider.id);
                    }
                },
                {
                    text: this.translateSvc.instant('alert.button.remove'),
                    role: 'confirm',
                    handler: async () => {
                        if (order[date]) { //check if order includes selected meal date
                            if (order[date].orders[mealId]) { //check if order date includes selected meals
                                order[date].orders[mealId].qty = 0
                                order[date].orders[mealId].box = 0
                            }
                            const index = Object.keys(order[date].orders).findIndex(o => order[date].orders[o].qty > 0);
                            if (index < 0) {
                                order[date].delivery.price = 0
                            }
                            this.helperSvc.orderNewMeals -= 1;
                        }
                        await this.updateOrder(order, provider.id);
                        console.log("ORDR: ", this.getOrder())
                    }
                }
            ]
        });
        await alert.present();
    }

    async updateOrder(order, providerId: string | number) {
        const userOrder = JSON.parse(await this.storageSvc.getItem('userOrder'));
        this.orderSubject.next(order);
        if (order === null) {
            delete userOrder[providerId];
        } else {
            userOrder[providerId] = order;
        }
        await this.storageSvc.setItem('userOrder', JSON.stringify(userOrder));
    }

    isDayMealBox(day: string): boolean {
        const order = this.getOrder();
        if (Object.keys(order[day].orders)) {
            for (let meal of Object.keys(order[day].orders)) {
                if(order[day].orders[meal].box){
                    return true
                }
            }
        }
        return false
    }

    onBoxChange(day: string, event: any): void {
        const order = this.getOrder();
        if (Object.keys(order[day].orders)) {
            for (let meal of Object.keys(order[day].orders)) {
                order[day].orders[meal].box = event.target.checked
            }
        }
    }

    isDayOrderEmpty(day: string): boolean {
        const order = this.getOrder();
        const index = Object.keys(order[day].orders).findIndex(o => order[day].orders[o].qty > 0);
        return index <= -1;
    }


    showMealAvailable(order: any, date: string, mealId: string, availableCount: string): boolean {
        if (availableCount) {
            return (Number(availableCount) / 4) >= Number(availableCount) - this.getMealCount(order, date, mealId);
        }
        return false;
    }

    getMealAvailable(order: any, date: string, mealId: string, availableCount: string): number {
        if (availableCount && (Number(availableCount) / 4) >= Number(availableCount) - this.getMealCount(order, date, mealId)) {
            return Number(availableCount) - this.getMealCount(order, date, mealId)
        }
    }

    getMealCount(order: any, date: string, mealId: string) {
        if (order)
            return order[date] ? order[date].orders[mealId] ? order[date].orders[mealId].qty : 0 : 0;
    }

    mealAvailable(dayMenu: any, mealId: string, menu: any = null) {
        return dayMenu.find(m => m.id === mealId).available
    }

    getHistoryMealPrices(meal: any) {
        return this.helperSvc.groupByKey(meal, 'price');
    }
}
