import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'app-mobile-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {
    public currentPage: string;

    constructor(
        public router: Router,
        public platform: Platform
    ) {
        this.currentPage = this.router.url;

    }


    async navTo(page: string): Promise<void> {
        await this.router.navigate([page]);
    }

}
