import {Injectable, Optional} from '@angular/core';
import * as dayjs from 'dayjs';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsService} from './translations.service';
import {App} from "@capacitor/app";
import {AlertController, IonRouterOutlet, Platform} from "@ionic/angular";
import {ProviderListModel} from "../models/provider_list.model";
import {ProviderModel} from "../models/provider.model";
import {UserRoleEnum} from "../enums/user_role.enum";
import {UserService} from "./user.service";
import {ApiService} from "./api.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public today = dayjs().format('YYYY-MM-DD');
    tap = 0;
    public userAcronym: string;
    public user: any;

    public orderNewMeals: any = new BehaviorSubject<number>(0);

    //orderNewMeals: number = 0;
    orderComplete: boolean = false;

    constructor(
        private translateSvc: TranslateService,
        private translationsSvc: TranslationsService,
        private alertCtrl: AlertController,
        private platform: Platform,
        private userSvc: UserService,
        private apiSvc: ApiService,
        @Optional() private routerOutlet?: IonRouterOutlet
    ) {
    }

    getFormattedDay(date: string, shortLong: boolean = false) {
        return date === this.today ? this.translateSvc.instant('text.today') : this.getDayName(date, this.translationsSvc.getCurrentLocale(),shortLong) + ' ' + dayjs(date).format('DD. MM. YYYY');
    }

    getDayName(dateStr, locale, shortLong) {
        var date = new Date(dateStr);
        if (window.innerWidth > 360) {
            return date.toLocaleDateString(locale, {weekday: 'long'});
        }
        return date.toLocaleDateString(locale, {weekday: shortLong ? 'short' : 'long'});
    }

    getDayOrderLength(orderObject): number {
        return Object.keys(orderObject).length;
    }

    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    groupByKey(array, key) {
        return array
            .reduce((hash, obj) => {
                if (obj[key] === undefined) {
                    return hash;
                }
                return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)})
            }, {})
    }

    exitAppOnDoubleTap() {
        if (this.platform.is('android')) {
            this.platform.backButton.subscribeWithPriority(10, async () => {
                if (!this.routerOutlet?.canGoBack()) {
                    this.tap++;
                    if (this.tap == 2) await this.presentExitAppAlert();
                }
            });
        }
    }

    async presentExitAppAlert() {
        const alert = await this.alertCtrl.create({
            header: this.translateSvc.instant('text.app_exit_title'),
            message: this.translateSvc.instant('text.app_exit_message'),
            buttons: [
                {
                    text: this.translateSvc.instant('text.app_exit_cancel'),
                    handler: () => {
                        this.tap = 0
                    }
                },
                {
                    text: this.translateSvc.instant('text.app_exit_submit'),
                    handler: () => {
                        this.tap = 0
                        App.exitApp();
                    }
                }
            ]
        });
        await alert.present();
    }

    public async initUserAndProviders() {
        if (this.userSvc.getUser()) {
            this.user = this.userSvc.getUser().user;
            this.userAcronym = this.userSvc.getUserAcronym(this.user);
            const providers: ProviderListModel[] = await this.apiSvc.getProviderList();
            if (providers && providers.length) {
                await this.userSvc.setProviderList(providers);
                const provider: ProviderModel = await this.apiSvc.getProviderInfo(providers[0].id);
                provider.no_debt = providers[providers.findIndex(pr => pr.id === Number(provider.id))].no_debt;
                this.userSvc.setProvider(provider);
                if (this.user.role === UserRoleEnum.USER) {
                    this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].more_lunches
                }
                if (this.user.role === UserRoleEnum.FIRM) {
                    if (!providers[providers.findIndex(l => l.id === Number(provider.id))].bulk_orders) {
                        if (providers[providers.findIndex(l => l.id === Number(provider.id))].more_lunches) {
                            this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].more_lunches
                        } else {
                            this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].bulk_orders
                        }

                    } else {
                        this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].bulk_orders
                    }
                }

            }
        }
    }
}
