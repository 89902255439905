import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {UserService} from './user.service';
import {lastValueFrom} from 'rxjs';
import {TranslationsService} from './translations.service';
import * as Sentry from '@sentry/capacitor';


@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private timeOut = 20000;

    constructor(
        private http: HttpClient,
        private userSvc: UserService,
        private translateSvc: TranslationsService
    ) {
    }

    public async send<T>(
        method: string,
        path: string,
        body: any = null
    ): Promise<T> {
        const options: any = {
            body,
            headers: new HttpHeaders()
        };
        return await this.request(method, path, options);
    }

    private async request(method: string, path: string, options: any): Promise<any> {
        let tries = 3;
        while (tries-- >= 0) {
            try {
                if (await this.userSvc.isLogged() && await this.userSvc.getCurrentToken()) {
                    options.headers = options.headers.set('Authorization', 'Bearer ' + await this.userSvc.getCurrentToken());
                }
                options.headers = options.headers.set('Accept-Language', this.translateSvc.activeLngSubject.value);
                options.headers = options.headers.set('X-Powered-By', "App");
                const fullPath = environment.apiUrl + path;
                const response = lastValueFrom(this.http.request(method, fullPath, options).pipe(timeout(this.timeOut)));
                return response;
            } catch (e) {
                Sentry.captureException('HTTP request: ' + method + ': ' + environment.apiUrl + path + JSON.stringify(e, options));
                if (tries === 0) {
                    throw e;
                }
            }
        }
    }
}
